import { useEffect } from 'react';

import type { ResponseError } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import type { Report } from '@import-io/types/report-types';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query/src/types';

import type { UseListParamsValue } from 'common/hooks/use-list-query-params';
import { singleValueStoreCreator } from 'common/utils/store-utils';
import { getReportsList, useVisibleReportsList } from 'features/reports/common/hooks/use-reports-hooks';
import type { ExtendedReport } from 'features/reports/common/reports-types';

const FIRST_REPORT_KEY = 'first-report';
export const useFirstVisibleReport = (
  params?: UseListParamsValue<ExtendedReport>,
  refetchEnabled?: boolean,
): UseQueryResult<Report | undefined | null, ResponseError> => {
  return useQuery({
    queryKey: [FIRST_REPORT_KEY, JSON.stringify(params ?? {})],
    queryFn: async () => {
      try {
        const list = await getReportsList({ ...(params ?? {}), page: 1, pageSize: 1 });
        return list?.[0] ?? null;
      } catch (error) {
        console.error('Fetch report error:', error);
        return null;
      }
    },
    refetchInterval: Boolean(refetchEnabled) ? 10000 : false,
  });
};

export const useFirstVisibleReportGuid = () => {
  const { items: reports, isPending } = useVisibleReportsList();

  return { guid: isPresent(reports[0]) ? reports[0].guid : null, isLoading: isPending };
};

const USER_HAS_REPORTS_KEY = 'user-has-reports';
const useUserHasReportsStore = singleValueStoreCreator<boolean | null>(USER_HAS_REPORTS_KEY, null);
export const useUserHasReports = () => {
  const { data: firstReport, isPending, isError } = useFirstVisibleReport();
  const { value, setValue } = useUserHasReportsStore();

  useEffect(() => {
    if (!isPresent(value) && !isPending) {
      setValue(isPresent(firstReport));
    }
  }, [setValue, firstReport, value, isPending]);

  return { isPending: isPending, isError: isError, value: value, setValue: setValue };
};
