import { useEffect } from 'react';

import type { ResponseError } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import type { Extractor } from '@import-io/types';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query/src/types';

import type { UseListParamsValue } from 'common/hooks/use-list-query-params';
import { singleValueStoreCreator } from 'common/utils/store-utils';
import { getExtractorsList, useVisibleExtractorsList } from 'features/extractors/hooks/use-extractors-hooks';

export const FIRST_EXTRACTOR_KEY = 'first-extractor';

export const useFirstVisibleExtractor = (
  params?: UseListParamsValue<Extractor>,
  refetchEnabled?: boolean,
): UseQueryResult<Extractor | null, ResponseError> => {
  return useQuery<Extractor | null, ResponseError>({
    queryKey: [FIRST_EXTRACTOR_KEY, JSON.stringify(params ?? {})],
    queryFn: async () => {
      try {
        const list = await getExtractorsList({ ...(params ?? {}), page: 1, pageSize: 1 });
        return list?.[0] ?? null;
      } catch (error) {
        return null;
      }
    },
    refetchOnMount: false,
    refetchInterval: Boolean(refetchEnabled) ? 10000 : false,
  });
};

export const useFirstVisibleExtractorGuid = () => {
  const { items: extractors, isPending } = useVisibleExtractorsList();

  return { guid: isPresent(extractors[0]) ? extractors[0].guid : null, isLoading: isPending };
};

const USER_HAS_EXTRACTORS_KEY = 'user-has-extractors';
const useUserHasExtractorsStore = singleValueStoreCreator<boolean | null>(USER_HAS_EXTRACTORS_KEY, null);
export const useUserHasExtractors = () => {
  const { data: firstExtractor, isPending, isError } = useFirstVisibleExtractor();
  const { value, setValue } = useUserHasExtractorsStore();

  useEffect(() => {
    if (!isPresent(value) && !isPending) {
      setValue(isPresent(firstExtractor));
    }
  }, [setValue, firstExtractor, value, isPending]);

  return { isPending: isPending, isError: isError, value: value, setValue: setValue };
};
