import { extractorApi, ObjectStoreQueryRequestBuilder } from '@import-io/js-sdk';
import { ObjectStoreQueryFilter } from '@import-io/js-sdk/models/object-store-query-filter';
import { isPresent } from '@import-io/typeguards';
import type { Extractor } from '@import-io/types';
import { toast } from 'sonner';

import { history } from 'app/app-history';
import { sendEvent } from 'common/events/events-api';
import { EventType } from 'common/events/events-types';
import { createEntityManager } from 'common/hooks/entity-manager/create-entity-manager';
import type { GetListParams } from 'common/hooks/entity-manager/types';
import { createUseListQueryParams } from 'common/hooks/use-list-query-params';
import { useUrlSearchParamsString } from 'common/hooks/use-url-search-params-string';
import { EXTRACTORS_PAGE_URL } from 'common/routes/routes-constants';
import { getExtractorUrl } from 'features/extractors/extractors-utils';
import { useCurrentExtractorTab } from 'features/extractors/hooks/use-current-extractor-tab';
import { useUserHasExtractors } from 'features/extractors/hooks/use-first-visible-extractor';
import { selectCurrentUser } from 'features/user/auth/user-auth-query';
import { selectSubscriptionQueryData } from 'features/user/subscription/subscription-query';

export const EXTRACTORS_KEY = 'extractors';

export const useExtractorsQueryParams = createUseListQueryParams<Extractor>({
  prefix: 'e',
});

export const getExtractorsList = ({ search, filter, sort, page, pageSize }: GetListParams<Extractor>) => {
  const q = new ObjectStoreQueryRequestBuilder()
    .setSortBy(sort?.property ?? 'meta_created_at')
    .setSortDesc(sort?.isDescending ?? true)
    .setPageLimit(pageSize ?? 30)
    .setPageNumber(page ?? 1)
    .setShowArchived(false)
    .setShowMine(true);

  if (Boolean(search)) {
    q.addFilter('name', new ObjectStoreQueryFilter('like', `%${search}%`));
  }

  if (isPresent(filter)) {
    Object.keys(filter).forEach((key) => {
      q.addFilter(key, new ObjectStoreQueryFilter('eq', filter[key]));
    });
  }

  return extractorApi.query(q.build());
};

const extractorsManager = createEntityManager<Extractor>({
  rootKey: EXTRACTORS_KEY,
  defaultListParams: {
    sort: {
      property: 'meta_created_at',
      isDescending: true,
    },
  },
  searchField: 'name',
  idField: 'guid',
  crudConfig: {
    getList: getExtractorsList,
    getById: (id) => extractorApi.get(id),
    updateById: (id, data) => extractorApi.update(id, data),
    deleteById: async (id: string) => {
      await extractorApi.update(id, { archived: true });
    },
    create: (data) => extractorApi.create(data),
  },
});

export const useExtractorsList = extractorsManager.useList;
export const useExtractor = extractorsManager.useById;
export const useUpdateExtractor = extractorsManager.useUpdateById;

export const updateExtractorInCache = extractorsManager.updateByIdInCache;

export const getExtractorInCache = extractorsManager.getByIdInCache;

export const invalidateExtractorsList = extractorsManager.invalidateList;

export const useCreateExtractor = () => {
  const { resetAll } = useExtractorsQueryParams();
  const { setValue: setHasExtractors } = useUserHasExtractors();

  const errorMessage = 'Failed to create extractor';

  return extractorsManager.useCreate({
    onSuccess: () => {
      resetAll();
      setHasExtractors(true);
    },
    onError: (e) => {
      console.error(errorMessage, e);
      void toast.error(errorMessage);
    },
  });
};

export const useVisibleExtractorsList = () => {
  const { value: params } = useExtractorsQueryParams();
  return useExtractorsList(params);
};

export const useDeleteExtractor = () => {
  const { guid: userId, email } = selectCurrentUser();
  const subscription = selectSubscriptionQueryData();
  const basePlanCode = subscription?.basePlanCode ?? '';
  const { items } = useVisibleExtractorsList();
  const searchParams = useUrlSearchParamsString();
  const tab = useCurrentExtractorTab();

  return extractorsManager.useDeleteById({
    optimistic: false,
    onSuccess: (_, { id }) => {
      void sendEvent({
        type: EventType.EXTRACTOR_DELETED,
        data: {
          user: {
            id: userId,
            email: email,
            planCode: basePlanCode,
          },
          extractor: {
            id: id!,
          },
        },
      });
      void toast.success('Extractor deleted');
      const deletedIndex = items.findIndex((e) => e.guid === id);
      const extractor = items[deletedIndex - 1] ?? items[deletedIndex + 1];
      if (isPresent(extractor)) {
        history.replace(`${getExtractorUrl(extractor.guid, tab)}${searchParams}`);
      } else {
        history.replace(EXTRACTORS_PAGE_URL);
      }
    },
    onError: () => {
      void toast.error('Failed to delete extractor');
    },
  });
};
