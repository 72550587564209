// Unregister /requests-interceptor.worker.js

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .getRegistrations()
    .then((registrations) => {
      registrations.forEach((registration) => {
        void registration.unregister();
      });
    })
    .catch((error) => {
      console.error('Error unregistering service workers:', error);
    });
}
