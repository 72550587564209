import type { JSX } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { isGuid } from '@import-io/typeguards';

import PageNotFound from 'common/components/error/PageNotFound';
import * as routesConstants from 'common/routes/routes-constants';

const ShortExtractorUrlRedirect = (): JSX.Element | null => {
  const { guid } = useParams();
  return isGuid(guid) ? <Navigate replace to={`${routesConstants.EXTRACTORS_PAGE_URL}/${guid}/history`} /> : <PageNotFound />;
};

export default ShortExtractorUrlRedirect;
