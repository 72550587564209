import { useMemo } from 'react';

import { isPresent } from '@import-io/typeguards';
import dayjs from 'dayjs';

import { useCurrentUser } from 'features/user/auth/user-auth-hooks';

export const usePortalEnabled = () => {
  const { data: user } = useCurrentUser({ enabled: true });
  return useMemo(() => {
    if (!isPresent(user)) {
      return false;
    }
    const registeredTimestamp = user._meta?.creationTimestamp;
    if (!isPresent(registeredTimestamp)) {
      return false;
    }
    return dayjs(registeredTimestamp).isBefore(dayjs().year(2023));
  }, [user]);
};
