import type { JSX } from 'react';
import { lazy, Suspense, useContext } from 'react';

import Modal from 'antd/lib/modal';

import PageLoader from 'common/components/PageLoader';
import { LoadingSpinner } from 'common/components/shadcn/loading-spinner';
import type { SubscriptionModalContextValue } from 'features/user/subscription/modal';
import { SubscriptionModalContext } from 'features/user/subscription/modal';
import { useCurrentSubscription } from 'features/user/subscription/subscription-hooks';

import styles from './SubscriptionModal.module.less';

const loadingPage = <PageLoader />;

const SubscriptionModalContent = lazy(() => import('./SubscriptionModalContent'));

const SubscriptionModal = (): JSX.Element => {
  const { hideModal, isOpen } = useContext<SubscriptionModalContextValue>(SubscriptionModalContext);
  const { isLoading } = useCurrentSubscription({ enabled: isOpen });
  return (
    <Modal centered footer={null} maskClosable={false} onCancel={hideModal} open={isOpen} width={900}>
      <Suspense fallback={loadingPage}>
        {isLoading ? (
          <div className={`flex align-center justify-center ${styles.loader}`}>
            <LoadingSpinner size="lg" />
          </div>
        ) : (
          <SubscriptionModalContent />
        )}
      </Suspense>
    </Modal>
  );
};

export default SubscriptionModal;
