import { Provider } from 'react-redux';

import { endpoints } from '@import-io/js-sdk';
import { QueryClientProvider } from '@tanstack/react-query';
import message from 'antd/lib/message';
import { NuqsAdapter } from 'nuqs/adapters/react';
import { createRoot } from 'react-dom/client';
import { HistoryRouter } from 'redux-first-history/rr6';

import AppContainer from 'app/components/AppContainer';
import SentryContainer from 'app/components/SentryContainer';
import { AppModalProvider } from 'common/components/modal/AppModalProvider';
import { Toaster } from 'common/components/shadcn/sonner';
import { TooltipProvider } from 'common/components/shadcn/tooltip';
import { TooltipProvider as MyTooltipProvider } from 'common/components/tooltip/TooltipProvider';
import { DEFAULT_MESSAGE_DURATION } from 'common/messages/messages-constants';
import { queryClient } from 'common/query/query-constants';

import { reduxHistory, store } from './store';

import '../style/main.less';
import './sw';

const LOGOUT_URL = `https://auth-api.${endpoints.rootDomain}/auth/logout`;
window.onLoggedOut = () => {
  window.location.replace(LOGOUT_URL);
};

message.config({ duration: DEFAULT_MESSAGE_DURATION });

const root = createRoot(document.getElementById('lightning'));

root.render(
  <NuqsAdapter>
    <Provider store={store}>
      <Toaster position={'bottom-center'} />
      <AppModalProvider>
        <SentryContainer>
          <HistoryRouter history={reduxHistory}>
            <QueryClientProvider client={queryClient}>
              <TooltipProvider>
                <MyTooltipProvider followMouse={false} hideDelay={200} minScreenOffset={4} moveAnimationDuration={400} offset={6}>
                  <AppContainer />
                </MyTooltipProvider>
              </TooltipProvider>
            </QueryClientProvider>
          </HistoryRouter>
        </SentryContainer>
      </AppModalProvider>
    </Provider>
  </NuqsAdapter>,
);
