import { useEffect } from 'react';

import { isPresent } from '@import-io/typeguards';

import { useCurrentUser } from 'features/user/auth/user-auth-hooks';
import { useCurrentSubscription } from 'features/user/subscription/subscription-hooks';

declare global {
  interface Window {
    fcWidget?: {
      setExternalId: (val: string) => void;
      user: {
        setFirstName: (val: string) => void;
        setEmail: (val: string) => void;
        setProperties: (props: Record<string, string | number | boolean | undefined>) => void;
      };
    };
  }
}

export const useSyncFreshChatUserDetails = () => {
  const { data: user } = useCurrentUser();
  const { data: subscription } = useCurrentSubscription();

  useEffect(() => {
    const widget = window.fcWidget;
    if (!isPresent(user) || !isPresent(widget)) {
      return;
    }
    widget.setExternalId(user.guid);
    widget.user.setFirstName(user.firstName ?? '');
    widget.user.setEmail(user.email ?? '');

    const { planCode, basePlanCode, nextPlanCode, created, accountManagementUrl, usage, usageStartDate } = subscription ?? {};
    widget.user.setProperties({
      userId: user.guid,
      username: user.username,
      planCode: planCode,
      basePlanCode: basePlanCode,
      nextPlanCode: nextPlanCode,
      created: created,
      accountManagementUrl: accountManagementUrl,
      usage: usage,
      usageStartDate: usageStartDate,
    });
  }, [user, subscription]);
};
