import type { JSX } from 'react';

import { isPresent } from '@import-io/typeguards';

import { useLightningRedirect } from 'app/app-hooks';
import AppSidebar from 'app/components/AppSidebar';
import PageLoader from 'common/components/PageLoader';
import { useSyncFreshChatUserDetails } from 'common/hooks/use-sync-fresh-chat-user-details';
import AppRoutes from 'common/routes/components/AppRoutes';
import { useUserHasExtractors } from 'features/extractors/hooks/use-first-visible-extractor';
import { useUserHasReports } from 'features/reports/hooks/use-first-visible-report';
import { useIdentifyPosthog } from 'features/user/analytics/use-identify-posthog';
import { useInitializeUserPilot } from 'features/user/analytics/userpilot-hooks';
import { useCurrentUser, useGetApiKey } from 'features/user/auth/user-auth-hooks';
import { SubscriptionModal, SubscriptionModalProvider } from 'features/user/subscription/modal';
import { useCurrentSubscription, useSubscriptionFeatureFlags } from 'features/user/subscription/subscription-hooks';

const AppContainer = (): JSX.Element => {
  // load check eagerly asap
  useUserHasExtractors();
  useUserHasReports();

  const { data: user, isLoading: isUserLoading } = useCurrentUser({
    enabled: true,
    refetchOnMount: true,
  });
  const { data: subscription, isLoading: isSubscriptionLoading } = useCurrentSubscription({
    enabled: isPresent(user),
    refetchOnMount: true,
  });
  useSubscriptionFeatureFlags({ enabled: true });
  useInitializeUserPilot(subscription);
  useIdentifyPosthog(subscription);
  useGetApiKey();
  useLightningRedirect();
  useSyncFreshChatUserDetails();

  // app content makes sense only after current user and subscription are loaded
  return isUserLoading || isSubscriptionLoading ? (
    <PageLoader />
  ) : (
    <div className={`w-screen h-full grid grid-cols-[80px_1fr] bg-background overflow-hidden`}>
      <AppSidebar />
      <SubscriptionModalProvider>
        <AppRoutes />
        <SubscriptionModal />
      </SubscriptionModalProvider>
    </div>
  );
};

export default AppContainer;
