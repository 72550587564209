import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { isGuid } from '@import-io/typeguards';

import { EXTRACTORS_PAGE_URL } from 'common/routes/routes-constants';

export const useCurrentExtractorTab = () => {
  const { pathname } = useLocation();
  return useMemo(() => {
    if (!pathname.startsWith(EXTRACTORS_PAGE_URL)) {
      return 'history';
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [_, guid, tab] = pathname.slice(EXTRACTORS_PAGE_URL.length).split('/');
    if (!isGuid(guid)) {
      return 'history';
    }
    return tab ?? 'history';
  }, [pathname]);
};
